<template>
    <hb-modal title="Generate Recurring Future Invoices" v-model="dialog"
        @close="$emit('close')">
        <template v-slot:subheader>
            Generate and send future recurring invoices for the selected spaces.
        </template>
        <template v-slot:content>
            <hb-form label="Select Spaces" description="Selected spaces will have invoices generated." required>
                <hb-select
                    v-model="form.leases"
                    :items="leases"
                    flat
                    data-vv-scope="form_leases"
                    data-vv-as="Form Leases"
                    id="form_leases"
                    name="form_leases"
                    multiple
                    full
                    :error="errors.collect('form_leases.form_leases').length > 0"
                    label="Select space(s)"
                    return-object
                >
                    <template v-slot:item="{ item }">
                        <v-row>
                            <span class="px-2">
                                <v-icon v-if="!!form.leases.find(l => l.id === item.id)">check_box</v-icon>
                                <v-icon v-else>check_box_outline_blank</v-icon>
                            </span>
                            <span>
                                <hb-space-icon small :type="item.Unit.type"></hb-space-icon>
                                <span class="hb-text-night">Space {{ item.Unit.number }}</span>
                            </span>
                        </v-row>
                    </template>
                    <template v-slot:selection="{ item, parent }">
                        <div class="d-flex flex-wrap align-center">
                            <hb-chip
                                @click="parent.selectItem(item)"
                                editable
                                class="mr-2 mb-2"
                            >
                                <hb-space-icon small :type="item.Unit.type" color="primary"></hb-space-icon>
                                <span>Space {{ item.Unit.number }}</span>
                            </hb-chip>
                        </div>
                    </template>
                </hb-select>
            </hb-form>
            <hb-form label="Send Invoice(s) to Tenant" description="Invoices can be sent to the tenant after being generated.">
                <HbCheckbox
                    v-model="form.send_to_tenant"
                    label="Send Invoice(s)"
                />
                <span class="hb-text-night-light hb-font-caption pl-8">{{ contact.email }}</span>
            </hb-form>
        </template>
        <template v-slot:right-actions>
            <hb-btn :disabled="!form.leases.length || generating_invoice" :loading="generating_invoice" @click="generateInvoices()" color="primary">{{ form.send_to_tenant ? 'Generate & Send' : 'Generate' }}</hb-btn>
        </template>
    </hb-modal>

</template>
<script>
import { notificationMixin } from '@/mixins/notificationMixin.js';
import { EventBus } from '../../EventBus.js';
import api from '../../assets/api.js';
import {  mapActions, mapGetters } from "vuex";
import HbSpaceIcon from '../../aviary/HbSpaceIcon.vue';
import HbSelect from '../../aviary/HbSelect.vue';
import HbChip from '../../aviary/HbChip.vue';

export default {
    name: "GenerateRecurringInvoices",
    props: ["value","contact","propertyView"],
    mixins: [notificationMixin],
    components: {
        HbSpaceIcon,
        HbSelect,
        HbChip
    },
    data() {
        return {
            leases: [],
            generating_invoice: false,
            form: {
                leases: [],
                send_to_tenant: false
            }
        }
    },
    async created() {
        this.leases = this.contact.Leases.filter(l => l.end_date === null && l.Unit.property_id === this.propertyView);
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
    },
    methods: {
        async generateInvoices(){
            this.generating_invoice = true;
            const payload = {
                leases: this.form.leases.map(l => ({ id: l.id })),
                send_to_tenant: this.form.send_to_tenant
            }
            try{
                let r = await api.post(this, api.LEASES + "generate-future-invoices", payload);
                this.showMessageNotification({type: "success", description: r.message});
                this.dialog = false;
            }catch(err){
                this.showMessageNotification({type: "error", description: err});
            } finally {
                this.generating_invoice = false;
            }
            
        }
    }
}
</script>